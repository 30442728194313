<template>
  <div class="user-manage-container">
    <el-card class="header table-search" v-if="false">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="产品名称">
          <el-input
            v-model="formInline.user"
            placeholder="请输入产品名称"
            clearable
          />
        </el-form-item>
        <el-form-item label="更新时间">
          <el-date-picker
            v-model="formInline.date"
            type="date"
            placeholder="请选择更新时间"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :icon="Search" @click="onSubmit"
            >查询</el-button
          >
          <el-button :icon="Delete" @click="reset"> 重置 </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <div class="filter-container">
        <el-button type="primary" :icon="Menu" @click="createExchangeCode">
          批量生成兑换码
        </el-button>
        <el-button type="primary" :icon="Download" @click="exportExchangeCode">
          导出兑换码
        </el-button>
      </div>
      <template v-if="false">
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          header-cell-class-name="table-header"
        >
          <el-table-column type="selection" width="55" align="center" />
          <el-table-column
            label="序号"
            type="index"
            align="center"
            width="70"
          />
          <!-- 姓名 -->
          <el-table-column prop="username" label="产品ID" align="center">
          </el-table-column>
          <!-- 联系方式 -->
          <el-table-column prop="mobile" label="产品名称" align="center">
          </el-table-column>
          <!-- 时间 -->
          <el-table-column label="更新时间" align="center">
            <template #default="{ row }">
              {{ $filters.dateFilter(row.openTime) }}
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
          class="pagination"
          :background="true"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </template>
    </el-card>
    <el-dialog
      v-model="centerDialogVisible"
      :title="isCreate ? '批量生成兑换码' : '导出兑换码'"
      width="500"
      align-center
      @close="dialogClose"
    >
      <el-input v-model="exchangeCodeNum" placeholder="请输入兑换码的数量" />
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="handleCreateExchangeCode">
            确认
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onActivated } from 'vue'
import { Delete, Search, Menu, Download } from '@element-plus/icons-vue'
// import { getUserManageList } from '@/api/user-manage'
import { exchangeCodeCreate, exchangeCodeExport } from '@/api/exchange'
import { ElMessage } from 'element-plus'

const formInline = reactive({
  user: '',
  region: '',
  date: ''
})
// 数据相关
const centerDialogVisible = ref(false)
const exchangeCodeNum = ref('')
const handleCreateExchangeCode = async () => {
  if (isCreate.value) {
    await exchangeCodeCreate({
      num: exchangeCodeNum.value
    })
    centerDialogVisible.value = false
    ElMessage.success('生成成功')
  } else {
    exportExchangeCodeFn()
  }
}

const isCreate = ref(false)
const createExchangeCode = () => {
  centerDialogVisible.value = true
  isCreate.value = true
}
const exportExchangeCode = () => {
  centerDialogVisible.value = true
  isCreate.value = false
}

const dialogClose = () => {
  exchangeCodeNum.value = ''
  isCreate.value = false
}

const exportExchangeCodeFn = async () => {
  const response = await exchangeCodeExport(exchangeCodeNum.value)
  const elink = document.createElement('a')
  elink.style.display = 'none'
  const blob = new Blob([response], {
    type:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; application/octet-stream'
  })
  const blobUrl = URL.createObjectURL(blob)
  console.log('blob', blobUrl)
  elink.href = blobUrl
  elink.download = '兑换码.xlsx'
  document.body.appendChild(elink)
  // elink.click()
  elink.dispatchEvent(
    new MouseEvent('click', { bubbles: true, cancelable: true, view: window })
  )
  window.URL.revokeObjectURL(blob) // 释放bolb对象
  document.body.removeChild(elink)
  centerDialogVisible.value = false
}

const tableData = ref([])
const total = ref(0)
const page = ref(1)
const size = ref(2)

const onSubmit = () => {
  console.log('submit!')
}

const reset = () => {
  console.log('reset!')
}

// 获取数据的方法
const getListData = async () => {
  total.value = 5
}
getListData()
// 处理导入用户后数据不重新加载的问题
onActivated(getListData)

// 分页相关
/**
 * size 改变触发
 */
const handleSizeChange = currentSize => {
  size.value = currentSize
  getListData()
}
/**
 * 页码改变触发
 */
const handleCurrentChange = currentPage => {
  page.value = currentPage
  getListData()
}
</script>

<style lang="scss" scoped>
@import '~@/styles/mixin.scss';
.user-manage-container {
  .header {
    @include searchForm;
  }

  .filter-container {
    .el-button {
      margin-bottom: 15px;
    }
  }

  :deep(.table-header) {
    @include tableThead;
  }
  .pagination {
    @include pagination;
  }
}
</style>
