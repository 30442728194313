import request from '@/utils/request'

/**
 * 批量生成兑换码
 */
export const exchangeCodeCreate = data => {
  return request({
    url: '/admin/redeem/create',
    method: 'POST',
    data
  })
}

/**
 * 导出兑换码
 */
export const exchangeCodeExport = data => {
  return request({
    url: `/admin/redeem/export?count=${data}`,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    responseType: 'blob'
  })
}
